import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import '../index.css';

function Header() {
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 5000);
  // }, []);

  return (
    <div>
      {/* Spinner Start */}
      {isLoading && (
        // <div
        //   id="spinner"
        //   className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center "
        // >

        //   <img src="assets/img/background-1.jpeg" alt="Loading..." className="wow fadeInDown" data-wow-delay="0.1s"
        //   // style={{ width: '100px', height: '100px' }}  // Adjust the size of your image as needed
        //   />

        // </div>
        <div
          id="spinner"
          className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
        >
          <img
            src="assets/img/background-1.jpeg"
            alt="Loading..."
            className="background-image"
          />
        </div>
      )}
      {/* Spinner End */}

      {/* Fly Bird Animation start */}
      <div class="bird-container bird-container-one">
        <div class="bird bird-one"></div>
      </div>
      <div class="bird-container bird-container-two">
        <div class="bird bird-two"></div>
      </div>
      <div class="bird-container bird-container-three">
        <div class="bird bird-three"></div>
      </div>
      <div class="bird-container bird-container-four">
        <div class="bird bird-four"></div>
      </div>
      {/* Fly Bird Animation end */}

      {/* Topbar Start */}
      <div className="container-fluid bg-dark px-5 d-none d-lg-block">
        <div className="row gx-0">
          <div className="col-lg-10 col-md-9 col-sm-10 text-center text-lg-start mb-2 mb-lg-0">
            <div
              className="d-inline-flex align-items-center"
              style={{ height: 45 }}  >
              <small className="me-3 text-light">
                <i className="fa fa-map-marker-alt me-2" />
                Office 1105B, Al Saman Tower B, Hamdan Street, Abu Dhabi, UAE
              </small>
              <small className="me-3 text-light">
                <i className="fa fa-phone-alt me-2" />
                +971 55 8797218
              </small>
              <small className="me-3 text-light">
                <i className="fa fa-phone-alt me-2" />
                +971 2 6751276
              </small>

              <small className="text-light">
                <i className="fa fa-envelope-open me-2" />
                adnan@highlandstravels.com
              </small>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-2 text-center text-lg-end">
            <div
              className="d-inline-flex align-items-center"
              style={{ height: 45 }} >
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                to=""
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter fw-normal" />
              </a>
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                to=""
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f fw-normal" />
              </a>
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                to=""
                href="https://www.linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin-in fw-normal" />
              </a>
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                to=""
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram fw-normal" />
              </a>
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle"
                to=""
                href="https://www.youtube.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube fw-normal" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Topbar End */}

      {/* Navbar & Hero Start */}
      <div className="container-fluid position-relative p-0">
        <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
          <Link to="/" className="navbar-brand p-0">
            <h1 className="text-primary m-0">
              {/* <i className="fa fa-solid fa-dove" /> */}
              <img
                src="assets/img/logo01.png"
                alt="Dove icon"
                width="36"
                height="36"
                style={{ width: '56px', height: '56px' }}
              />
              Highland Travel
            </h1>
            {/* <img className="img-fluid bg-light p-1" src="assets/img/logo.jpg" alt=""/> */}
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
              <Link to="/" className="nav-item nav-link">
                Home
              </Link>
              <Link to="/About" className="nav-item nav-link">
                About
              </Link>
              <Link to="/Services" className="nav-item nav-link">
                Services
              </Link>
              {/* <Link to="/Packages" className="nav-item nav-link">
              Packages
            </Link> */}
              {/* <div className="nav-item dropdown">
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown">
                Pages
              </Link>
              <div className="dropdown-menu m-0">
                <Link to="/Destination" className="dropdown-item">
                  Destination
                </Link>
                <Link to="/Booking" className="dropdown-item">
                  Booking
                </Link>
                <Link to="/Team" className="dropdown-item">
                  Travel Guides
                </Link>
                <Link to="/Testimonial" className="dropdown-item">
                  Testimonial
                </Link>
                <Link to="/Error" className="dropdown-item">
                  404 Page
                </Link>
              </div>
            </div> */}
              <Link to="/Contact" className="nav-item nav-link">
                Contact
              </Link>
            </div>
            <Link to="/Contact" className="btn btn-primary rounded-pill py-2 px-4">
              Register
            </Link>
          </div>
        </nav>
      </div>
      {/* Navbar & Hero End */}
    </div>
  )
}

export default Header