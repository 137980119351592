import React from 'react';
export const AboutUsContents = () => {
    return (
        <div>
            <div className="container-xxl py-5">
                {/* CEO MESSAGE */}
                <div className="container">
                    <div className="row g-5">

                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                            <h6 className="section-title bg-white text-start text-primary pe-3">
                                CEO Message
                            </h6>
                            <h1 className="mb-4">
                                Live is too short so go and <span className="text-primary">live your dream</span>
                            </h1>
                            <p className="mb-4">
                                With 20 years of experience in travel industry.I'm thrilled to introduce Highland Travel,a new venture designed to bring you unforgettable travel experience.Our mission is to make your journeys seamless, memorable, and tailored just for you. Whether you're planning a relaxing getaway or an adventurous escape, we're here to help every step of the way.
                            </p>
                            <p className="mb-4">
                                Thank you for trusting us to be part of your adventures. Here's to many miles together!
                            </p>
                            <p className="mb-4">
                                Co-founder & CEO<br />
                                Farah Adnan
                                {/* CEO, Highland Travel-L.L.L O.P.C */}
                            </p>

                            {/* <div className="row gy-2 gx-4 mb-4">
                                <div className="col-sm-6">
                                    <p className="mb-0">
                                        <i className="fa fa-arrow-right text-primary me-2" />
                                        
                                    </p>
                                </div>
                                 
                            </div> */}
                            <a className="btn btn-primary py-3 px-5 mt-2" href="">
                                Read More
                            </a>
                        </div>

                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{ minHeight: 400 }}>
                            <div className="position-relative h-100">
                                <img
                                    className="img-fluid position-absolute w-100 h-100"
                                    src="assets/img/ceo-message.jpg" style={{ objectFit: "cover" }}
                                />
                            </div>
                        </div>

                    </div>
                </div>

                {/* Co-FOUNDER MESSAGE */}
                <div className="container">
                    <div className="row g-5" style={{ marginTop: 0 }}>

                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{ minHeight: 400 }}>
                            <div className="position-relative h-100">
                                <img
                                    className="img-fluid position-absolute w-100 h-100"
                                    src="assets/img/manager-message.jpg" style={{ objectFit: "cover" }}
                                />
                            </div>
                        </div>

                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                            <h6 className="section-title bg-white text-start text-primary pe-3">
                                General Manager Message
                            </h6>
                            <h1 className="mb-4">
                                Your Trusted Travel Partner <span className="text-primary">Highland Travel</span>
                            </h1>
                            <p className="mb-4">
                                With a team of passionate travel experts, we are dedicated to providing you with seamless planning, exceptional service, and exclusive opportunities to explore the world. Whether it’s a family vacation, a romantic getaway, an adventure of a lifetime, or a valuable business trip, we are here to guide you every step of the way.
                            </p>
                            <p className="mb-4">
                                Thank you for choosing us as your travel partner. Let us make your next journey extraordinary!
                            </p>
                            <p className="mb-4">
                                Co-founder & General Manager<br />
                                Adnan Abbas
                            </p>
                            <a className="btn btn-primary py-3 px-5 mt-2" href="">
                                Read More
                            </a>
                        </div>

                    </div>
                </div>

                {/* About Us */}
                <div className="container">
                    <div className="row g-5" style={{ marginTop: 0 }}>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                            <h6 className="section-title bg-white text-start text-primary pe-3">
                                About Us
                            </h6>
                            <h1 className="mb-4">
                                Experience World-Class <span className="text-primary">Travel Solutions</span>
                            </h1>
                            <p className="mb-4">
                                Founded in 2020, Highland Travel is the best travel agency in the UAE.
                                As an IATA-certified agency, we provide exceptional travel solutions tailored to the unique needs of
                                both corporate and leisure travelers. Our reputation is built on precision, efficiency,
                                and a customer-centric approach, seamlessly blending decades of traditional travel expertise with modern,
                                trend-driven practices.
                            </p>
                            <p className="mb-4">
                                At Highland Travel, we offer a comprehensive suite of services that encompass everything from flight bookings to
                                complex travel itineraries, hotel reservations, transportation services, curated holiday packages,
                                and more. With a robust global network and cutting-edge technology, we ensure seamless travel experiences
                                for our clients, whether they are navigating a simple trip or an intricate itinerary involving multiple destinations.
                            </p>
                            <div className="row gy-2 gx-4 mb-4">
                                <div className="col-sm-6">
                                    <p className="mb-0">
                                        <i className="fa fa-arrow-right text-primary me-2" />
                                        First Class Flights
                                    </p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0">
                                        <i className="fa fa-arrow-right text-primary me-2" />
                                        Handpicked Hotels
                                    </p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0">
                                        <i className="fa fa-arrow-right text-primary me-2" />5 Star
                                        Accommodations
                                    </p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0">
                                        <i className="fa fa-arrow-right text-primary me-2" />
                                        Latest Model Vehicles
                                    </p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0">
                                        <i className="fa fa-arrow-right text-primary me-2" />
                                        150 Premium City Tours
                                    </p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0">
                                        <i className="fa fa-arrow-right text-primary me-2" />
                                        24/7 Service
                                    </p>
                                </div>
                            </div>
                            <a className="btn btn-primary py-3 px-5 mt-2" href="">
                                Read More
                            </a>
                        </div>

                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{ minHeight: 400 }}>
                            <div className="position-relative h-100">
                                <img
                                    className="img-fluid position-absolute w-100 h-100"
                                    src="assets/img/pexels-asadphoto-1450353.jpg" style={{ objectFit: "cover" }}
                                />
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
};

export const ServiceList = () => {
    return (
        <div>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title bg-white text-center text-primary px-3">
                            Services
                        </h6>
                        <h1 className="mb-5">Our Services</h1>
                    </div>

                    <div className="row g-4">

                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="team-item border-radius">
                                <div className="overflow-hidden border-radius-top">
                                    <img className="img-fluid" src="assets/img/AirlineReservation.jpeg" alt='logo' style={{ minHeight: 169, maxHeight: 169, maxWidth: '100%', minWidth: '100%' }} />
                                </div>

                                <div className="text-center p-4">
                                    <h5 className="mb-0">Airline Booking</h5>
                                    <small>
                                        Seamless flight bookings with expert support, offering optimal routes, competitive fares, and flexible schedules.
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="team-item border-radius">
                                <div className="overflow-hidden border-radius-top">
                                    <img className="img-fluid" src="assets/img/hotel.jpeg" alt='logo' style={{ minHeight: 169, maxHeight: 169, maxWidth: '100%', minWidth: '100%' }} />
                                </div>

                                <div className="text-center p-4">
                                    <h5 className="mb-0">Hotel Reservation</h5>
                                    <small>
                                        Highland provides accommodations for all, from luxury to budget options, ensuring every traveler finds the perfect fit.
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="team-item border-radius">
                                <div className="overflow-hidden border-radius-top">
                                    <img className="img-fluid" src="assets/img/WorldWideTours.jpeg" alt='logo' style={{ minHeight: 169, maxHeight: 169, maxWidth: '100%', minWidth: '100%' }} />
                                </div>

                                <div className="text-center p-4">
                                    <h5 className="mb-0">WorldWide Tours & Excursion</h5>
                                    <small>
                                    Explore breathtaking destinations worldwide with Tours your trusted gateway unforgettable
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="team-item border-radius">
                                <div className="overflow-hidden border-radius-top">
                                    <img className="img-fluid" src="assets/img/CorporateTravel.jpeg" alt='logo' style={{ minHeight: 169, maxHeight: 169, maxWidth: '100%', minWidth: '100%' }} />
                                </div>

                                <div className="text-center p-4">
                                    <h5 className="mb-0">Corporate Travel</h5>
                                    <small>
                                        Your ultimate companion for exploring the world – find expert Travel Guides to make every journey unforgettable and seamless!
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="team-item border-radius">
                                <div className="overflow-hidden border-radius-top">
                                    <img className="img-fluid" src="assets/img/PrivateJet.jpg" alt='logo' style={{ minHeight: 169, maxHeight: 169, maxWidth: '100%', minWidth: '100%' }} />
                                </div>

                                <div className="text-center p-4">
                                    <h5 className="mb-0">Private Jet </h5>
                                    <small>
                                        Experience unparalleled luxury and convenience with our exclusive private jet services, tailored to provide ultimate comfort, style, and a seamless travel experience
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="team-item border-radius">
                                <div className="overflow-hidden border-radius-top">
                                    <img className="img-fluid" src="assets/img/LuxuryCruise.jpeg" alt='logo' style={{ minHeight: 169, maxHeight: 169, maxWidth: '100%', minWidth: '100%' }} />
                                </div>

                                <div className="text-center p-4">
                                    <h5 className="mb-0">Luxury Cruise</h5>
                                    <small>
                                        Set sail on a journey of elegance and indulgence with our luxury cruise experiences, where world-class amenities meet breathtaking destinations.
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="team-item border-radius">
                                <div className="overflow-hidden border-radius-top">
                                    <img className="img-fluid" src="assets/img/LimoMeet.jpg" alt='logo' style={{ minHeight: 169, maxHeight: 169, maxWidth: '100%', minWidth: '100%' }} />
                                </div>

                                <div className="text-center p-4">
                                    <h5 className="mb-0">Limo meet and assists</h5>
                                    <small>
                                        Arrive in style and comfort with our premium Limo Meet and Assist services, offering seamless transfers and personalized attention for a truly luxurious travel experience.
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="team-item border-radius">
                                <div className="overflow-hidden border-radius-top">
                                    <img className="img-fluid" src="assets/img/VisaAndPackages.jpeg" alt='logo' style={{ minHeight: 169, maxHeight: 169, maxWidth: '100%', minWidth: '100%' }} />
                                </div>

                                <div className="text-center p-4">
                                    <h5 className="mb-0">Holiday packages and Visa assistance </h5>
                                    <small>
                                        Comprehensive visa assistance and tailored travel packages for smooth, stress-free, and truly enjoyable international journeys
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Second Row End */}
                    </div>

                    {/*
                    <div className="row g-4">
                      <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s"
                        // style={{ background: "url(assets/img/hotel.jpeg) no-repeat center center" }}
                        >
                            <div className="service-item rounded pt-3">
                                <div className="p-4">
                                    <i className="fa fa-3x fa-globe text-primary mb-4" />
                                    <h5>WorldWide Tours</h5>
                                    <p>
                                        Explore breathtaking destinations across the globe with WorldWide Tours – your gateway to unforgettable adventures!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item rounded pt-3">
                                <div className="p-4">
                                    <i className="fa fa-3x fa-hotel text-primary mb-4" />
                                    <h5>Hotel Reservation</h5>
                                    <p>
                                        Highland offers diverse accommodations, ranging from luxury to budget, to suit every traveler's needs.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item rounded pt-3">
                                <div className="p-4">
                                    <i className="fa fa-3x fa-user text-primary mb-4" />
                                    <h5>Travel Guides</h5>
                                    <p>
                                        Your ultimate companion for exploring the world – discover expert Travel Guides for every journey
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="service-item rounded pt-3">
                                <div className="p-4">
                                    <i className="fa fa-3x fa-cog text-primary mb-4" />
                                    <h5>Event Management</h5>
                                    <p>
                                        Seamlessly plan and execute unforgettable experiences with our expert Event Management services!
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item rounded pt-3">
                                <div className="p-4">
                                    <i className="fa fa-3x fa-globe text-primary mb-4" />
                                    <h5>Airline Reservation</h5>
                                    <p>
                                        Seamless flight bookings with expert support, offering optimal routes, competitive fares, and flexible schedules.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item rounded pt-3">
                                <div className="p-4">
                                    <i className="fa fa-3x fa-hotel text-primary mb-4" />
                                    <h5>Corporate Travel</h5>
                                    <p>
                                        Streamlined corporate travel solutions tailored to your business needs for seamless and efficient journeys.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item rounded pt-3">
                                <div className="p-4">
                                    <i className="fa fa-3x fa-user text-primary mb-4" />
                                    <h5>Visa & packages</h5>
                                    <p>
                                        Visa assistance and tailored travel packages for smooth, stress-free, and enjoyable international journeys.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="service-item rounded pt-3">
                                <div className="p-4">
                                    <i className="fa fa-3x fa-cog text-primary mb-4" />
                                    <h5>Event Management</h5>
                                    <p>
                                        Seamlessly plan and execute unforgettable experiences with our expert Event Management services!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                        */}
                </div>
            </div>
        </div>
    )
}
// export default {AboutUsContents, ServiceList};