import React from 'react'
import { Link } from "react-router-dom";
export default function Footer() {

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth", // Smooth scrolling
  //   });
  // };


  return (
    <div>
      {/* Footer Start */}
      <div
        className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">

            <div className="col-lg-3 col-md-6 pt-5">



              <h4 className="text-white mb-3 pt-2">Company</h4>
              <Link className="btn btn-link" to="/About">
                About Us
              </Link>
              <Link className="btn btn-link" to="/Contact">
                Contact Us
              </Link>
              <Link className="btn btn-link" to="/privacyPolicy">
                Privacy Policy
              </Link>
              <Link className="btn btn-link" to="/termsAndCondition">
                Terms &amp; Condition
              </Link>
              <a className="btn btn-link" href="/">
                FAQs &amp; Help
              </a>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="row g-2 pt-2">
                <div className="col-4">
                  {/* <img src="https://static.wixstatic.com/media/74bd0e_e097818821554994b891ecd457dbf38f~mv2.png/v1/fill/w_68,h_48,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/iata-iagto_edited.png" alt="hotel" style={{ width: 68, height: 48 }} /> */}
                </div>

                <div className="col-4">
                  <img
                    src="assets/img/logoFull.jpg"
                    alt="hotel" style={{ width: 68, height: 48, borderRadius: 6}} />
                </div>

                <div className="col-4">
                  {/* <img src="https://static.wixstatic.com/media/74bd0e_e097818821554994b891ecd457dbf38f~mv2.png/v1/fill/w_68,h_48,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/iata-iagto_edited.png" alt="hotel" style={{ width: 68, height: 48 }} /> */}
                </div>


              </div>
              
              {/* <h4 className="text-white mb-3">Contact</h4> */}
              <p className="mb-2 g-2 pt-2">
                <i className="fa fa-map-marker-alt me-3" />
                Office 1105B, Al Saman Tower B, Hamdan Street, Abu Dhabi, UAE
              </p>
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3" />
                +971 55 8797218
              </p>
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3" />
                +971 2 6751276
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope me-3" />
                adnan@highlandstravels.com
              </p>
              <div className="d-flex pt-2">
                <a className="btn btn-outline-light btn-social" href="https://www.twitter.com">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-outline-light btn-social" href="https://www.facebook.com">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-outline-light btn-social" href="https://www.youtube.com">
                  <i className="fab fa-youtube" />
                </a>
                <a className="btn btn-outline-light btn-social" href="https://www.linkedin.com">
                  <i className="fab fa-linkedin-in" />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              {/* <h4 className="text-white mb-3">Gallery</h4> */}
              <div className="row g-2 pt-2">
                <div className="col-4">
                  {/* <img src="https://static.wixstatic.com/media/74bd0e_e097818821554994b891ecd457dbf38f~mv2.png/v1/fill/w_68,h_48,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/iata-iagto_edited.png" alt="hotel" style={{ width: 68, height: 48 }} /> */}
                </div>

                <div className="col-4">
                  <img src="https://static.wixstatic.com/media/74bd0e_e097818821554994b891ecd457dbf38f~mv2.png/v1/fill/w_68,h_48,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/iata-iagto_edited.png" alt="hotel" style={{ width: 68, height: 48 }} />
                </div>

                <div className="col-4">
                  {/* <img src="https://static.wixstatic.com/media/74bd0e_e097818821554994b891ecd457dbf38f~mv2.png/v1/fill/w_68,h_48,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/iata-iagto_edited.png" alt="hotel" style={{ width: 68, height: 48 }} /> */}
                </div>

                <div className="col-12">
                  <p>Highland Travel is a proud member of International Air Transport Association (IATA) and has been providing excellent services</p>
                </div>
              </div>

              <div className="row d-flex pt-2">
                <div className='col-12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKk_qabFABDq3RsczTTA3Jx3JrYwCEFcW0mpyVZcm2Wq1cbmqjNnAoyZ4ptDuBiO0XT_Q&usqp=CAU" alt="American Express" style={{ width: '50px', height: 'auto' }} />
                  <img src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png" alt="Visa" style={{ width: '50px', height: 'auto' }} />
                  <img src="https://upload.wikimedia.org/wikipedia/commons/0/04/Mastercard-logo.png" alt="MasterCard" style={{ width: '50px', height: 'auto' }} />
                </div>
                <div className='col-12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                  <p>Pay Safely With Us. The payment is encrypted and transmitted securely with an SSL protocol</p>
                </div> 
              </div>

            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-3">Newsletter</h4>
              <p>Stay informed and inspired with the latest updates.</p>
              <div
                className="position-relative mx-auto"
                style={{ maxWidth: 400 }}
              >
                <input
                  className="form-control border-primary w-100 py-3 ps-4 pe-5"
                  type="text"
                  placeholder="Your email"
                />
                <button
                  type="button"
                  className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
                >
                  SignUp
                </button>
              </div>


              {/* <div className="row d-flex pt-2">
                <div className='col-12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKk_qabFABDq3RsczTTA3Jx3JrYwCEFcW0mpyVZcm2Wq1cbmqjNnAoyZ4ptDuBiO0XT_Q&usqp=CAU" alt="American Express" style={{ width: '50px', height: 'auto' }} />
                  <img src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png" alt="Visa" style={{ width: '50px', height: 'auto' }} />
                  <img src="https://upload.wikimedia.org/wikipedia/commons/0/04/Mastercard-logo.png" alt="MasterCard" style={{ width: '50px', height: 'auto' }} />
                </div>
                <div className='col-12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                  <p>Pay Safely With Us. The payment is encrypted and transmitted securely with an SSL protocol</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              ©{" "}
              <a className="border-bottom" href="#">
                Your Site Name
              </a>
              , All Right Reserved.
              Designed By{" "}
              <a className="border-bottom" href="https://github.com/ParasSalunke">
                Paras Salunke
              </a>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <div className="footer-menu">
                <a href="/">Home</a>
                <a href="/">Cookies</a>
                <a href="/">Help</a>
                <a href="/">FQAs</a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      </div>
      {/* Footer End */}
    </div>
  )
}
